// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(32),
    applicationId: z.string().min(1).max(21),
    samlRequestId: z.string().min(1).max(128),
    oidcState: z.string().max(32).nullable().optional(),
    relayState: z.string().max(256).nullable().optional(),
    rawAuthRequest: z.string().min(1),
    createdAt: z.number().optional(),
    expiresAt: z.number(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(32),
    applicationId: z.string().min(1).max(21),
    samlRequestId: z.string().min(1).max(128),
    oidcState: z.string().max(32).nullable(),
    relayState: z.string().max(256).nullable(),
    rawAuthRequest: z.string().min(1),
    createdAt: z.number(),
    expiresAt: z.number(),
});
export const SamlApplicationSessions = Object.freeze({
    table: 'saml_application_sessions',
    tableSingular: 'saml_application_session',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        applicationId: 'application_id',
        samlRequestId: 'saml_request_id',
        oidcState: 'oidc_state',
        relayState: 'relay_state',
        rawAuthRequest: 'raw_auth_request',
        createdAt: 'created_at',
        expiresAt: 'expires_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'applicationId',
        'samlRequestId',
        'oidcState',
        'relayState',
        'rawAuthRequest',
        'createdAt',
        'expiresAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
