/**
 * Logto-provided predefined subscription plan IDs.
 *
 * In theory, the subscription plan ID will be a random string,
 * but Logto provides some predefined subscription plans and their IDs are reserved plan IDs.
 */
export var ReservedPlanId;
(function (ReservedPlanId) {
    ReservedPlanId["Free"] = "free";
    /**
     * @deprecated
     * Grandfathered Pro plan ID deprecated from 2024-11.
     * Use {@link Pro202411} instead.
     */
    ReservedPlanId["Pro"] = "pro";
    ReservedPlanId["Development"] = "dev";
    /**
     * This plan ID is reserved for Admin tenant.
     * In our new pricing model, we plan to add a special plan for Admin tenant, previously, admin tenant is using the `pro` plan, which is not suitable.
     */
    ReservedPlanId["Admin"] = "admin";
    /**
     * The latest Pro plan ID applied from 2024-11.
     */
    ReservedPlanId["Pro202411"] = "pro-202411";
})(ReservedPlanId || (ReservedPlanId = {}));
/**
 * Tenant subscription related Redis cache keys.
 *
 * We use Redis to cache the tenant subscription data to reduce the number of requests to the Cloud.
 * Both @logto/core and @logto/cloud will need to access the cache, so we define the cache keys here as the SSOT.
 */
export var SubscriptionRedisCacheKey;
(function (SubscriptionRedisCacheKey) {
    SubscriptionRedisCacheKey["Subscription"] = "subscription";
})(SubscriptionRedisCacheKey || (SubscriptionRedisCacheKey = {}));
